@import 'variables';

$workout-card-border-width: 0.25vw; // useful for offsets

.workout-card-container {
  display: flex;
  flex-direction: column;
  outline: none !important;
  width: 18.1rem;
  .workout-card {
    aspect-ratio: 16/9;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    background-size: 100% 100%;
    border-radius: 0.2rem;
    transition: all 0.2s ease-in-out;
    color: #000912;
    padding: 0.75rem;
    // box-shadow: $card-box-shadow;

    .subtitle {
      display: none;
    }

    .workout-card-bottom-row {
      display: none;
      .workout-card-duration-container {
        margin-right: 1rem;

        .play-icon-container {
          border: 0.1rem solid #000;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.5rem;
          height: 1rem;
          border-radius: 0.2rem;

          .mat-icon {
            transition: none;
            transform: scale(0.6);
            height: unset;
            width: unset;
          }
          .material-icons {
            font-size: unset;
          }
        }

        .workout-card-duration {
          margin-top: 0.5rem;
        }
      }

      .workout-card-intensity-container {
        .intensity-icon-container {
          display: flex;
          align-items: flex-end;

          .intensity-icon {
            height: 1rem;
          }
        }
        .workout-card-intensity {
          margin-top: 0.5rem;
        }
      }
    }
  }
  &:focus {
    .workout-card {
      transform: scale(1.07);
      justify-content: space-between;
      box-shadow: $card-box-shadow-focus;
      .sub-title {
        display: unset;
        font-size: 0.9rem;
      }
      .workout-card-bottom-row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .workout-card-duration-container {
          .play-icon-container {
            border-color: #fff;
          }
        }
      }
    }
  }

  .workout-title {
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 1.18rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .round-avatar {
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      margin-right: 1rem;
    }
  }
}

.feed-scheduled-content-container {
  .workout-card {
    .round-avatar {
      background-color: unset;
      width: 3rem;
      height: 3rem;
    }
  }
  .workout-card-container {
    &:focus {
      .workout-card {
        .round-avatar {
          background-color: white;
        }
      }
    }
  }
}

.feed-workout-card {
  height: 17.8vh;
}
