.profile-container {
  width: 90vw;
  padding: 3rem 3rem 3rem 6rem;

  .profile-header {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-bottom: 4rem;

    .avatar-container {
      display: flex;
      .name {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
      .avatar-wrapper {
        margin-right: 2rem;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      .log-out-button {
        align-self: center;
        width: 10rem;
      }
    }
  }
  .profile-form {
    display: flex;
    .label-column {
      display: flex;
      flex-direction: column;
      min-width: 5rem;
    }
    .value-column {
      display: flex;
      flex-direction: column;
    }
  }
}
