.carousel-parent {
  width: 100%;
  overflow-x: hidden;
  /*  Move these styles when we make coursel a shared component */
  .carousel-container {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    gap: 1rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
}

.carousel-container {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
