@import 'variables';
$feed-item-border-width: 1.25rem; // useful for offsets

.feed-content-titles {
  margin-left: $feed-item-border-width;

  .scheduled-feed-title {
    margin: 0;
  }
}

.feed-container {
  padding-left: 4rem;

  .feed-content-container {
    margin-bottom: 2rem;
  }
}

.feed-scheduled-content-container {
  .no-workouts-message-container {
    width: 100%;
    text-align: center;
    .no-workouts-message {
      margin-top: 0;
    }
  }
}

.feed-content-items-container {
  .carousel-container {
    padding: 0.75rem 0 2rem 1.25rem;
    scroll-padding-left: 1.25rem;
    .feed-content {
      display: flex;
      min-width: 14.6vw;
      &.aspect-wide {
        min-width: 16.7vw;
        .feed-item-wrapper {
          width: 100%;
          .feed-item-container {
            aspect-ratio: 2/1;
          }
        }
      }
      &.aspect-trainer {
        min-width: 14vw;
        .feed-item-container {
          aspect-ratio: 17/22;
        }
      }
    }
  }
}

.feed-item-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  box-sizing: border-box;
  width: 100%;
  .feed-item-container {
    width: 100%;
    border-radius: 0.4rem;
    box-shadow: $card-box-shadow;
    background-size: 100% 100%;
    box-sizing: content-box;
    &:focus {
      outline: none;
      transform: scale(1.1);
      transition: all 0.3s;
      box-shadow: $card-box-shadow-focus;
    }
    &.image-stacked {
      width: 100%;
      aspect-ratio: 16/9;
    }
    &.text-overlay-image {
      align-items: flex-end;
      justify-content: baseline;
      .feed-item-title {
        margin-left: 0.25rem;
      }
    }
    &.aspect-wide {
      aspect-ratio: 2/1;
      width: 100%;
    }
  }
  .feed-item-title {
    margin-top: 1rem;
    margin-left: 0.25rem;
  }
}
