@font-face {
  font-display: block;
  font-family: 'WorkSans-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./WorkSans-SemiBold.woff2') format('woff2'),
    url('./WorkSans-SemiBold.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'WorkSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./WorkSans-Regular.woff2') format('woff2'),
    url('./WorkSans-Regular.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'WorkSans-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./WorkSans-Medium.woff2') format('woff2'),
    url('./WorkSans-Medium.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('./MaterialIcons-Regular.woff2') format('woff2'),
    url('./MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./MaterialIcons-Outlined.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  width: 3rem;
  height: 3rem;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
