.search-page-title {
  font-size: 2rem;
  margin-bottom: 4rem;
}
.search-page-wrapper {
  width: 90vw;
  padding: 2rem 6rem;
  .search-input-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    .search-title {
      margin: 0;
      margin-right: 1rem;
      transform: scale(1.5);
    }
    .search-form {
      width: 100vw;
      .workout-search-input {
        border-bottom: 0.1rem solid #ffffff;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        outline: none;
        width: 100%;
        min-height: 2.5rem;
        padding: 0.5rem;
        background-color: transparent;
        caret-color: $brand-color-primary;
        color: #ffffff;
        font-family: $base-typography-font-family;
        font-size: 1.5rem;
        &:focus {
          border-bottom: 0.25rem solid #ffffff;
        }
      }
      input[type='search']::-webkit-search-decoration,
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-results-button,
      input[type='search']::-webkit-search-results-decoration {
        display: none;
      }
    }
  }

  .placeholder-container {
    width: 90vw;
    display: flex;
    justify-content: center;
    margin-top: 20%;
  }
}
