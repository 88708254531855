.carousel {
  &:focus {
    outline: 1rem solid $brand-color-primary;
  }
  .slide {
    aspect-ratio: 48/17;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    transition: background-image 0.3s ease-in-out;
    padding: 3rem 5rem 2rem 6rem;

    .banner-header {
      font-size: 4rem;
      font-weight: bold;
    }
    .banner-body {
      font-size: 1.6rem;
    }
    .mat-icon {
      transform: scale(0.5);
    }
  }
}
