.landing-container {
  display: flex;
  height: 100vh;
  width: 100vw;

  .actions-column {
    padding: 10% 5%;
    width: 36%;
    height: 100%;
    background-color: #1b1b1b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .aaptiv-landing-logo {
      width: 100%;
    }

    app-shared-button {
      width: 100%;
    }
  }
  .image-column {
    background-color: #00287a;
    background-image: url('../../assets/img/aaptiv_bg-main.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: 100vh;
    width: 74%;
  }
}

.sign-in-button {
  width: 100%;
  background-color: #003db8;
  outline: none;
}
