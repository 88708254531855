.channel-details-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 3em;
  width: 100vw;
  height: 100vh;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  .channel-details-grid {
    width: 100%;
  }
  .channel-details-banner {
    display: flex;
    min-width: 100%;
    aspect-ratio: 4 / 1;
    background-size: cover;
    background-position: center;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 2rem 6rem;
    .channel-details-title {
      width: 40%;
      font-size: 2.5rem;
    }
    .channel-details-description {
      width: 60%;
    }
  }
}
