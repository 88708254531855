.exercise-card-container {
  width: 10vw;
  height: 12rem;
  background-color: #2a2a2a;
  border-radius: 0.2rem;
  margin-right: 1.5rem;
  box-sizing: border-box;
  border: 0.5rem solid transparent;
  overflow: hidden;
  &:focus {
    outline: 0 solid transparent;
    outline: 0.25rem solid #fff;
    outline-offset: -0.25rem;

    .exercise-card-image {
      border-radius: 0;
    }
  }

  .exercise-card-image {
    width: 100%;
    border-radius: 0.2rem;
    margin-bottom: 0.3rem;
  }

  .title-container {
    padding: 0 0.25rem;
  }
}

.ai-exercise-card {
  .exercise-card-container {
    height: auto;
    .exercise-card-image {
      margin-top: -1.7rem;
      object-fit: cover;
      height: 10.5rem;
    }
  }
}
