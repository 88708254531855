.trainer-profile-container {
  display: flex;
  flex-direction: column;
  padding-top: 1.5em;
  padding-right: 1.5em;
  padding-left: 3em;
  padding-bottom: 3em;
  width: 90vw;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}
