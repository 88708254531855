// main colors
$brand-color-primary: #003db8;
$brand-color-secondary: #3e3e3e;
$brand-color-error: #f8616f;
$background-color: #1b1b1b;
$focus-border-color: #fff;

//card
$card-border-radius: 0.2rem;
$card-box-shadow: 0rem 1.75rem 1.02rem -0.68rem rgba(0, 0, 0, 0.1),
  0rem 1.68rem 0.98rem -0.68rem rgba(0, 0, 0, 0.12),
  0rem 1.58rem 0.92rem -0.68rem rgba(0, 0, 0, 0.13),
  0rem 1.48rem 0.86rem -0.68rem rgba(0, 0, 0, 0.14),
  0rem 1.38rem 0.8rem -0.68rem rgba(0, 0, 0, 0.15),
  0rem 1.28rem 0.74rem -0.68rem rgba(0, 0, 0, 0.16),
  0rem 1.08rem 0.68rem -0.68rem rgba(0, 0, 0, 0.17);

$card-box-shadow-focus: 0rem 1.75rem 1.02rem -0.68rem rgba(0, 0, 0, 0.1),
  0rem 1.68rem 0.98rem -0.68rem rgba(0, 0, 0, 0.12),
  0rem 1.58rem 0.92rem -0.68rem rgba(0, 0, 0, 0.13),
  0rem 1.48rem 0.86rem -0.68rem rgba(0, 0, 0, 0.14),
  0rem 1.38rem 0.8rem -0.68rem rgba(0, 0, 0, 0.15),
  0rem 1.28rem 0.74rem -0.68rem rgba(0, 0, 0, 0.16),
  0rem 1.08rem 0.68rem -0.68rem rgba(0, 0, 0, 0.17),
  1rem 0rem 0.98rem -0.68rem rgba(0, 0, 0, 0.83),
  -1rem 0rem 0.98rem -0.68rem rgba(0, 0, 0, 0.83);

// set common typography
$base-typography-font-family: WorkSans-Regular, sans-serif;
$base-typography-font-family-medium: WorkSans-Medium, sans-serif;
$base-typography-font-family-heavy: WorkSans-Bold, sans-serif;
$base-typography-font-family-size: 14px;

/*Breakpoint Sizes*/

$xsmall-portrait-height: 768px;
$xsmall-portrait-width: 576px;
$xsmall-landscape-width: 768px;
$xsmall-landscape-height: 576px;

/*9.7 Pro/Air/iPad & 7.9 Mini*/
$sm-portrait-height: 1024px;
$sm-portrait-width: 768px;
$sm-landscape-height: 768px;
$sm-landscape-width: 1024px;
/*11,10.5 Pro/Air/iPad*/

$m-portrait-height: 1194px;
$m-portrait-width: 834px;
$m-landscape-height: 834px;
$m-landscape-width: 1194px;
/*10.2*/

$m10-portrait-height: 1080px;
$m10-portrait-width: 810px;
$m10-landscape-height: 810px;
$m10-landscape-width: 1080px;
/*12.9 Pro*/

$lg-portrait-height: 1366px;
$lg-portrait-width: 1024px;
$lg-landscape-height: 1024px;
$lg-landscape-width: 1366px;

/*HD TV*/
$hd-tv-landscape-height: 1080px;
$hd-tv-landscape-width: 1920px;

/*4k TV*/
$fourk-tv-landscape-height: 2160px;
$fourk-tv-landscape-width: 4096px;
