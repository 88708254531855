@import '../../styles/abstracts/variables';

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 2rem;
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    .header-logo {
      max-width: 75%;
      margin: 0 auto;
    }
    @media (max-width: $m-landscape-width) {
      margin-bottom: 1rem;
    }
  }
  .login-actions-container {
    display: flex;
    width: 100%;
    .signin-form-container {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .auth-title {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;

        @media (max-width: $m10-landscape-width) {
          margin-bottom: 1em;
        }
      }
      .auth-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: #fff;
        .auth-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 1rem 0;
        }
        .auth-button {
          width: 49%;
          font-size: 1rem;
          height: 3rem;
        }
        .forgot-password-container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 1rem;
          button {
            &:focus {
              color: $brand-color-primary;
            }
          }
        }

        @media (min-width: $xsmall-landscape-width) {
          width: 52%;
        }

        .password-input {
          margin-bottom: 1rem;
        }

        .terms-button {
          width: fit-content;
          margin: 0 auto;
          .mat-mdc-button-wrapper {
            font-size: 1rem;
          }
          &:focus {
            background-color: $brand-color-primary;
          }
        }
      }
    }
    .qr-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 1rem;
      top: 16rem;
      max-width: 18rem;

      .qr-instructions {
        text-align: center;
      }
      qrcode {
        .qr-code {
          canvas {
            width: 6rem !important;
            height: 6rem !important;
          }
        }
      }
    }
  }
  @media (orientation: portrait) {
    justify-content: center;
    padding: 0 3rem;
  }

  @media (orientation: landscape) {
    height: unset;
  }
}
