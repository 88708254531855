/* Importing Bootstrap SCSS file. */
// @import '../../../node_modules/bootstrap';

// BOOTSTRAP OVERRIDES
// Button Overrides

.btn {
  min-width: 10em;
  height: 3em;
  box-shadow: none !important;
  color: white;
  &:active {
    background-color: $brand-color-primary !important;
    border: 0.25em solid white !important;
  }
  &:hover {
    background-color: $brand-color-primary;
    border: 0.25em solid $brand-color-primary !important;
    font-weight: 700;
    color: white;
  }
  &:focus {
    border: 0.25em solid #ffffff !important;
  }
}

.btn-primary {
  border: none;
  background-color: $brand-color-secondary;
}

.btn-primary-outline {
  border: 0.25em solid $brand-color-secondary;
}

.btn-link {
  &:focus,
  &:hover {
    background: none;
    font-weight: 700;
    color: $brand-color-primary;
    border: none !important;
  }
}

// Form Overrides
.form-group {
  display: flex;
  flex-direction: column;
  input {
    color: white !important;
    background-color: #1b1b1b;
    border: 1px solid white;
    border-radius: 0.45rem;
    margin-bottom: 1rem;
    &:focus-within {
      border: 0.25rem solid white;
      outline-color: white;
      background-color: #1b1b1b;
    }
  }
  .email-buttons {
    display: flex;
    flex-direction: column;
    .email-error {
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }
    .email-next {
      width: 100%;
      height: 3rem;
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  .password-buttons {
    display: flex;
    justify-content: flex-end;
    .password-visibility {
      margin-left: 1rem;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px rgb(27, 27, 27) inset !important;
    -webkit-box-shadow: 0 0 0 30px rgb(27, 27, 27) inset !important;
    -webkit-text-fill-color: white;
  }
}

.form-control {
  box-shadow: none !important;
  border: none;
  border-radius: 0%;
  min-height: 3.4rem;
  width: 100%;
  padding-left: 1rem;
}

.modal-fullscreen {
  .modal-content {
    height: 100vh;
    width: 100vw;
  }
  &.modal-dialog {
    max-width: 100vw;
    margin: 0 !important;
  }
  overflow: hidden;
}

.modal-content {
  background-color: #1b1b1b !important;
  color: white;
}

.black-text-outline {
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.button-primary.mat-button {
  background-color: #8a929e;

  &:focus {
    background-color: $brand-color-primary;
  }
}
