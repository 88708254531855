/* You can add global styles to this file, and also import other style files */

// Local Imports
@import './base/auth';
@import './base/banner-carousel';
@import './base/base';
@import './base/carousel';
@import './base/channel-details';
@import './base/exercise-card';
@import './base/feed';
@import './base/grid';
@import './base/landing';
@import './base/navigation';
@import './base/profile';
@import './base/search';
@import './base/workout-card';
@import './base/workout';
@import './base/trainer';
@import './theme/theme';
@import 'variables';

* {
  box-sizing: border-box;
}

*:focus-visible {
  outline: none;
}

html {
  font-size: 1.12vw;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: $base-typography-font-family;
  overflow-x: hidden;
  background-color: $background-color;
  //temporary buitton color until we add theming
  --mdc-text-button-label-text-color: $brand-color-primary;
  //temporary buitton border until we add theming
  .mdc-button {
    border: 1px solid #fff;
  }
  .mdc-button.cdk-focused {
    border: 4px solid #fff;
  }
}

.flex-1 {
  flex: 1;
}

:focus {
  &.focus-border {
    border: 0.25rem solid $brand-color-primary;
  }

  &.focus-fill {
    background-color: $brand-color-primary;
  }
}

.dialog-container .mdc-dialog__container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b1b1b;
  overflow-y: hidden;
}

.terms-dialog {
  overflow-y: auto;
}

.body-wrapper {
  width: 100%;
  padding-left: 4rem;
}
