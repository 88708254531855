.workout-container {
  color: #ffffff;
  width: 90vw;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 2rem 0 0 4rem;
  .top-actions {
    width: 100%;
    padding: 0 2rem;
    height: 7%;
  }

  .workout-info {
    width: 100%;
    display: flex;
    height: 50%;
    .workout-image-container {
      flex: 1;
      padding: 2rem;
      .workout-image {
        aspect-ratio: 16 / 9;
        border-radius: 0.2rem;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center fixed;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &:focus-within {
          outline: 0.25rem solid #ffffff;
        }

        .workout-video-button {
          transform: scale(2);
          color: $brand-color-primary;

          .mat-icon {
            transform: scale(2);
          }
          &:focus {
            color: #fff;
            transform: scale(3);
          }
        }
      }
    }
    .workout-details-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 2rem;

      .music-toggle-container {
        padding: 0.5rem 0.5rem 0.5rem 0;
        display: flex;
        flex-direction: column;

        .music-embed-message {
          margin-bottom: 0.5rem;
        }

        .music-option-text {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 0;

          .music-toggle-status {
            font-weight: bold;
            vertical-align: baseline;
          }
        }

        .detail {
          margin: 0;
        }

        .mat-mdc-slide-toggle {
          transform: scale(0.75);
        }
      }

      .title-row {
        .title {
          margin: 0;
        }
      }

      .workout-details-row {
        display: flex;
        flex-direction: row;

        .workout-details-column {
          display: flex;
          flex-direction: column;

          &:not(.last) {
            margin-right: 5rem;
          }

          .detail {
            margin-top: 0;
            &:not(:last-child) {
              margin-bottom: 0.9rem;
            }
          }

          .music-toggle-container {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 0.2rem solid #fff;

            .music-embed-message {
              margin-bottom: 0.5rem;
            }

            .music-option-text {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 0;
            }

            .detail {
              margin-bottom: 0;
            }

            .mat-mdc-slide-toggle {
              transform: scale(0.75);
            }
          }

          .rating-container {
            display: flex;
            flex-direction: row;

            .detail {
              margin-right: 0.3rem;
            }

            .mediaType {
              margin-right: 0.3rem;
            }
          }
        }
      }
    }
  }
}

.workout-back-button.mat-mdc-button {
  border: 0.2rem solid #2a2a2a;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    border: 0.2rem solid $brand-color-primary;
  }
  @media screen and (max-width: 1600px) {
    display: none;
  }
}

.post-workout-container {
  width: 90vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 6rem;

  .post-workout-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .post-workout-header {
      margin-bottom: 3rem;
    }

    .post-workout-survey {
      margin-bottom: 3rem;
    }
    .survey-buttons-container {
      display: flex;
      flex-direction: row;

      .survey-button {
        width: 6rem;
        height: 6rem;
        margin-right: 0.75rem;
        background-color: transparent;
        border: 0.25rem solid #8a929e;
        pointer-events: none;
        &:focus {
          border: 0.25rem solid #fff;
          background-color: $brand-color-primary;
        }
        &:hover {
          background-color: $brand-color-primary;
        }

        .rating-star {
          margin-right: 0;
          transform: scale(2.5);
          color: #8a929e;
        }
        .mat-mdc-focus-indicator {
          display: none;
        }
        .survey-button-inside {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          .check-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
          }
        }
      }
      .survey-button-active {
        background-color: $brand-color-primary;
        border: 0.25rem solid #fff;

        .rating-star {
          transform: scale(2.5);
          color: #fff;
        }
      }
    }
  }
}
